<!-- 用户订单 -->
<template>
  <div class="ucenter">
    <!-- 订单退款理由和客服反馈弹窗 -->
    <el-dialog title="退款理由" :visible.sync="dialogReasonVisible">
      <el-form :model="form">
        <el-form-item label="申请退款原因" label-width="120">
          <el-input
            v-model.trim="form.reason"
            placeholder="请输入申请退款原因"
            style="min-width: 800px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogReasonVisible = false">取 消</el-button>
        <el-button type="primary" @click="refundOrder">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 订单退货单号弹窗 -->
    <el-dialog
      title="退单申请"
      :visible.sync="dialogFormVisible"
      custom-class="refundInfoDialog"
    >
      <el-row id="refund-dialog">
        <el-col :span="12" style="font-size: 16px; color: #3b3e44"
          >退货地址</el-col
        >
        <el-col
          :span="12"
          style="font-size: 12px; text-align: right; color: #757479"
        >
          <i
            class="el-icon-warning"
            style="color: rgb(246, 193, 87); font-size: 14px; margin-right: 5px"
          ></i
          >拒收到付件，严禁在包裹中夹带现金！</el-col
        >
      </el-row>
      <el-row style="margin: 10px 0 20px 0">
        <el-col :span="12"
          >地址：<span style="color: #ff6700"
            >江苏省扬州市邗江区朱塘路110号普创电子二楼右门</span
          ></el-col
        >
        <el-col :span="5"
          >联系人：<span style="color: #ff6700">退货组</span></el-col
        >
        <el-col :span="5"
          >联系电话：<span style="color: #ff6700">15861388608</span></el-col
        >
        <el-col :span="2">复制</el-col>
      </el-row>
      <el-form :model="form" :inline="true">
        <el-form-item label="快递" label-width="120">
          <el-select
            v-model.trim="form.express_company"
            placeholder="请选择快递"
            style="min-width: 300px"
          >
            <el-option label="申通" value="申通"></el-option>
            <el-option label="圆通" value="圆通"></el-option>
            <el-option label="中通" value="中通"></el-option>
            <el-option label="韵达" value="韵达"></el-option>
            <el-option label="极兔" value="极兔"></el-option>
            <el-option label="邮政" value="邮政"></el-option>
            <el-option label="顺丰" value="顺丰"></el-option>
            <el-option label="丰网" value="丰网"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退单运单号" label-width="120">
          <el-input
            v-model.trim="form.express_no"
            placeholder="请输入用户的退单的运单号"
            style="min-width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="completeRefundExpressInfo"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 物流查询弹窗 -->
    <el-dialog title="物流查询" :visible.sync="dialogShipVisable">
      <el-timeline :reverse="false">
        <el-timeline-item
          v-for="(item, index) in shipData"
          :key="index"
          :timestamp="item.time"
          :color="index > 0 ? '' : '#0bbd87'"
        >
          {{ item.context }}
        </el-timeline-item>
      </el-timeline>
    </el-dialog>

    <!-- 订单列表 -->
    <el-row>
      <el-col :span="4" class="sider">
        <div id="title">会员首页</div>
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item name="1">
            <template slot="title">
              <div class="collapse-item__title">交易中心</div>
            </template>
            <ul class="collapse-item__ul">
              <li
                v-for="(item, k) in tradeMenu"
                :key="k"
                @click="changeUrl(item)"
              >
                <span v-if="item.id === activeId" style="color: #ff414d">
                  {{ item.title }}
                </span>
                <span v-else>
                  {{ item.title }}
                </span>
              </li>
            </ul>
          </el-collapse-item>
        </el-collapse>
      </el-col>
      <el-col :span="20" style="padding: 0 20px">
        <!-- 我的订单 -->
        <div>
          <!-- 筛选框 -->
          <el-row>
            <el-collapse v-model="activeQuery" id="queryForm">
              <el-collapse-item title="我的订单" name="1">
                <el-form
                  ref="form"
                  :model="query"
                  label-width="100px"
                  style="margin-top: 25px"
                >
                  <el-row>
                    <el-col>
                      <el-form-item label="起止日期">
                        <el-col :span="6">
                          <el-date-picker
                            type="date"
                            placeholder="起始日期"
                            v-model="query.start"
                            value-format="yyyy-MM-dd"
                            style="width: 100%"
                          ></el-date-picker>
                        </el-col>
                        <el-col
                          class="line"
                          :span="1"
                          style="text-align: center"
                          >-</el-col
                        >
                        <el-col :span="6">
                          <el-date-picker
                            type="date"
                            placeholder="截止日期"
                            v-model="query.end"
                            value-format="yyyy-MM-dd"
                            style="width: 100%"
                          ></el-date-picker>
                        </el-col>
                        <el-col :span="11">
                          <el-row style="text-align: center">
                            <el-col :span="12">
                              <el-button type="danger" @click="orderQuery"
                                >查询订单</el-button
                              >
                            </el-col>
                            <el-col :span="12">
                              <el-button type="success" @click="orderExport"
                                >导出订单</el-button
                              >
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="订单号">
                        <el-input v-model="query.order_no"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="快递单号">
                        <el-input v-model="query.express_no"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="订单状态">
                        <el-select
                          v-model="query.status_code"
                          placeholder="请选择订单状态"
                        >
                          <el-option
                            v-for="(op, k) in orderStatusOptions"
                            :key="k"
                            :label="op.label"
                            :value="op.value"
                            >{{ op.label }}</el-option
                          >
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="收件人">
                        <el-input v-model="query.name"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="手机号码">
                        <el-input v-model="query.mobile"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="订单备注">
                        <el-input v-model="query.remark"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-collapse-item>
            </el-collapse>
          </el-row>
          <!-- 快捷查询 -->
          <el-row>
            <el-tabs
              v-model="query.status_code"
              type="card"
              @tab-click="quickQuery"
            >
              <el-tab-pane
                v-for="(op, k) in orderStatusOptions"
                :key="k"
                :label="op.label"
                :name="op.value"
              ></el-tab-pane>
            </el-tabs>
          </el-row>
          <!-- 置顶功能区 -->
          <el-row style="padding: 10px 0">
            <el-col :span="18">
              <div style="display: flex; flex-direction: row">
                <div>
                  <el-checkbox v-model="checked"></el-checkbox>
                </div>
                <div style="position: relative; top: -5px; margin-left: 15px">
                  <el-button type="success" size="mini" @click="batchPay"
                    >批量付款</el-button
                  >
                </div>
                <div style="position: relative; top: -5px; margin-left: 15px">
                  <el-button type="danger" size="mini" @click="batchCancel"
                    >批量取消</el-button
                  >
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div id="top-page">
                <el-button size="mini">上一页</el-button>
                <el-button size="mini">下一页</el-button>
              </div>
            </el-col>
          </el-row>
          <!-- 表头 -->
          <el-row id="theader">
            <el-col :span="6">订单号</el-col>
            <el-col :span="4">规格</el-col>
            <el-col :span="4">购/配/发</el-col>
            <el-col :span="4">总金额</el-col>
            <el-col :span="3">订单状态</el-col>
            <el-col :span="3">操作</el-col>
          </el-row>
          <!-- 表格数据 -->
          <el-row
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          >
            <el-row
              v-for="(item, index) in orders"
              :key="index"
              style="width: 100%"
            >
              <el-col :span="24">
                <el-row class="order-info">
                  <el-col :span="12">
                    <div class="order-info-main">
                      <span><el-checkbox v-model="checked"></el-checkbox></span>
                      <span style="margin: 0 10px 0 10px"
                        >订单号: {{ item.order_no }}</span
                      >
                      <span style="margin: 0 10px 0 10px"
                        ><i class="el-icon-document-copy"></i
                      ></span>
                      <span style="margin: 0 10px 0 10px"
                        >下单时间: {{ item.created_at }}</span
                      >
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="order-info-user">
                      <span style="margin: 0 20px 0 20px"
                        >收件人: {{ item.name }}</span
                      >
                      <span>手机: {{ item.mobile }}</span>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="7">
                <ul>
                  <li
                    v-for="(goods, i) in item.goods_list"
                    :key="i"
                    class="order-goods"
                  >
                    <div class="goods-image">
                      <router-link
                        :to="{
                          path: '/detail',
                          query: { id: goods.goods_id },
                        }"
                      >
                        <el-image :src="goods.thumbnail"></el-image>
                      </router-link>
                    </div>
                    <div class="goods-info">
                      <div class="goods-info-name">
                        <el-tooltip
                          :content="goods.sname + '   ' + goods.goods_name"
                          placement="top"
                        >
                          <span>{{
                            goods.goods_name + "   " + goods.sname
                          }}</span>
                        </el-tooltip>
                      </div>
                      <div class="goods-info-no">
                        <span>货号: </span>{{ goods.goods_sn }}
                      </div>
                      <div class="goods-info-price">
                        <span>单价: </span>￥{{ goods.price }}
                      </div>
                    </div>
                  </li>
                </ul>
              </el-col>
              <el-col :span="4">
                <ul>
                  <li
                    v-for="(goods, i) in item.goods_list"
                    :key="i"
                    class="order-goods"
                  >
                    <div class="goods-spec" style="width: 100%">
                      {{ goods.size + "," + goods.color }}
                    </div>
                  </li>
                </ul>
              </el-col>
              <el-col :span="4" style="">
                <ul>
                  <li
                    v-for="(goods, i) in item.goods_list"
                    :key="i"
                    class="order-goods"
                  >
                    <div class="goods-spec" style="width: 100%">
                      {{ goods.goods_num }} /
                      {{ goods.done_status > 0 ? goods.goods_num : 0 }} / -
                      {{
                        goods.goods_remark ? "(" + goods.goods_remark + ")" : ""
                      }}
                    </div>
                  </li>
                </ul>
              </el-col>
              <el-col :span="3">
                <div class="order-total-money">
                  <div>￥{{ item | orderTotalMoney }}</div>
                  <div>(运费：￥{{ item.express_fee }})</div>
                  <div>(金币：-￥{{ item.gold }})</div>
                  <div>(实付：￥{{ item | orderPayTotal }})</div>
                </div>
              </el-col>
              <el-col :span="3">
                <div class="order-status">
                  <div style="color: #db3032">{{ item | orderStatusText }}</div>
                </div>
              </el-col>
              <el-col :span="3">
                <div style="padding-top: 10px; text-align: center">
                  <a
                    class="order-action"
                    @click="cancleOrder(item.order_no)"
                    v-if="item.pay_status <= 0"
                    >取消订单</a
                  >
                  <a
                    class="order-action"
                    @click="payOrder(item)"
                    v-if="item.pay_status <= 0"
                    >立即支付</a
                  >
                  <!-- 申请退款/退货退款 -->
                  <a
                    class="order-action"
                    :disabled="item.refund_status ? true : false"
                    @click="openRefundDialog(item)"
                    v-if="
                      item.refund_service_status < 3 &&
                      item.pay_status == 1 &&
                      item.refund_status == 0
                    "
                    >{{ item.express_no ? "申请退货退款" : "申请退款" }}</a
                  >
                  <!-- 补充快递和退单号 -->
                  <a
                    class="order-action"
                    v-if="
                      item.refund_service_status == 3 && item.refund_status == 1
                    "
                    @click="openRefundInfoDialog(item.order_no)"
                    >补充退单号</a
                  >
                  <!-- 查看物流 -->
                  <a
                    class="order-action"
                    v-if="item.express_status"
                    @click="
                      getExpressInfo(item.express_company, item.express_no)
                    "
                    >查看物流</a
                  >
                  <!-- 订单详情 -->
                  <a class="order-action" @click="getOrderInfo(item)"
                    >订单详情</a
                  >
                </div>
              </el-col>
              <el-col :span="24">
                <el-row class="order-express">
                  <el-col :span="6" style="padding-left: 20px"
                    ><div>快递：{{ item.express_company }}</div></el-col
                  >
                  <el-col :span="6"
                    ><div class="goods-num">
                      共{{ item | orderGoodsNum }}件
                    </div></el-col
                  >
                  <el-col :span="12">
                    <div style="padding-left: 20px">
                      <span>快递单号: {{ item.express_no }}</span>
                      <span style="margin-left: 20px"
                        ><i class="el-icon-document-copy"></i
                      ></span>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-row>
          <!-- 底部功能区 -->
          <el-row id="bottom">
            <el-col
              :span="12"
              style="border-right: 1px solid #e3e3e3; padding-left: 10px"
            >
              <div style="display: flex; flex-direction: row">
                <div>
                  <el-checkbox v-model="checked">全选</el-checkbox>
                </div>
                <div style="position: relative; top: 0; margin-left: 15px">
                  <el-button type="success" size="mini" @click="batchPay"
                    >批量付款</el-button
                  >
                </div>
                <div style="position: relative; top: 0; margin-left: 15px">
                  <el-button type="danger" size="mini" @click="batchCancel"
                    >批量取消</el-button
                  >
                </div>
              </div>
            </el-col>
            <el-col :span="6">&nbsp;</el-col>
            <el-col :span="6">
              <div id="bottom-count">
                <div>
                  已选订单<span
                    style="color: #f03a3c; font-size: 16px; font-weight: bold"
                    >0</span
                  >个
                </div>
                <div style="margin-left: 30px">
                  合计<span
                    style="color: #f03a3c; font-size: 16px; font-weight: bold"
                    >￥10000</span
                  >
                </div>
              </div>
            </el-col>
          </el-row>
          <!-- 底部分页 -->
          <div id="pagination">
            <el-pagination
              background
              layout="prev, pager, next, total"
              :page-size="query.pageSize"
              :total="totalCount"
              @current-change="currentChange"
            ></el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getUcenterCollapseItems, getTotalPrice } from "@/util/common";

export default {
  name: "Order",
  data() {
    return {
      // 筛选框打开
      activeQuery: ["1"],
      // 左侧菜单选中
      activeName: "1",
      activeId: 1,
      // 订单状态编码格式 pay_status + express_status + done_status + refund_status + finish_status
      orderStatusOptions: [
        { label: "全部订单", value: "-" },
        { label: "等待支付", value: "00000" },
        { label: "等待发货", value: "10000" },
        { label: "等待配货", value: "11000" },
        { label: "配货完成", value: "11100" },
        { label: "申请退款", value: "1--10" },
        { label: "完结订单", value: "1---1" },
      ],
      tradeMenu: [],
      dialogReasonVisible: false,
      dialogFormVisible: false,
      dialogShipVisable: false,
      loading: true,
      orders: [],
      total: [],
      shipData: [],
      totalCount: 0,
      currentPage: 1, //当前页码
      checked: false,
      form: {
        order_express_no: "", // 订单运单号
        reason: "",
        order_no: "",
        express_company: "", // 退单快递
        express_no: "", // 退单运单号
      },
      query: {
        web: 1,
        page: 1,
        pageSize: 10,
        uid: 0,
        start: "",
        end: "",
        order_no: "",
        express_no: "",
        status_code: "-",
        name: "",
        mobile: "",
        remark: "",
      },
    };
  },
  filters: {
    /**
     * 获取订单总价
     * @param {*} item
     */
    orderTotalMoney(item) {
      return getTotalPrice(
        item.goods_total,
        item.express_fee,
        item.daifa_fee,
        item.check_fee,
        item.package_fee,
        item.brand_fee,
        item.wishcard_fee
      );
    },
    /**
     * 订单实付价格
     * @param {*} item
     */
    orderPayTotal(item) {
      const payTotal = getTotalPrice(
        item.goods_total,
        item.express_fee,
        item.daifa_fee,
        item.check_fee,
        item.package_fee,
        item.brand_fee,
        item.wishcard_fee
      );
      return parseFloat(parseFloat(payTotal) - parseFloat(item.gold)).toFixed(2)
    },
    /**
     * 订单商品总数
     * @param {*} item
     */
    orderGoodsNum(item) {
      let num = 0;
      item.goods_list.map((item) => {
        num += item.goods_num;
      });
      return num;
    },
    /**
     * 订单状态
     * @param {*} item
     */
    orderStatusText(item) {
      if (item.pay_status < 1) {
        return "等待支付";
      }
      if (item.refund_status > 0) {
        if (item.refund_service_status == 4) {
          return "退款完成";
        }
        if (item.refund_service_status == 5) {
          return "拒绝退款";
        }
        return "退款审核中";
      }
      if (item.express_status < 1) {
        return "等待发货";
      }
      if (item.done_status < 1) {
        return "等待配货";
      }
      if (item.finish_status > 0) {
        return "订单完结";
      } else {
        return "配货完成";
      }
    },
  },
  created() {
    this.tradeMenu = getUcenterCollapseItems();
    this.query.uid = this.$store.getters.getUser.id;
    this.getOrders();
  },
  methods: {
    /**
     * 切换链接
     * @param {*} item
     */
    changeUrl(item) {
      if (item.url) {
        this.activeId = item.id;
        this.$router.push(item.url);
      }
    },
    /**
     * 订单检索
     */
    orderQuery() {
      this.query.page = 1;
      this.loading = true;
      this.getOrders();
    },
    /**
     * 快捷查询
     */
    quickQuery() {
      this.query.page = 1;
      this.loading = true;
      this.getOrders();
    },
    /**
     * 订单导出
     */
    orderExport() {
      if (!this.$store.getters.getUser.id) {
        this.notifyError("请登录!");
      } else {
        const queryString = new URLSearchParams(this.query).toString();
        const url = this.$apiBaseUrl + '/web/order/export?' + queryString;
        window.open(url);
        this.notifySucceed("导出中，请稍候!");
      }
    },
    /**
     * 获取订单数据
     */
    async getOrders() {
      const { data } = await this.$axios.post(
        this.$apiBaseUrl + "/web/order/list",
        this.query
      );
      this.orders = data.data.list;
      this.totalCount = data.data.total;
      this.loading = false;
    },
    /**
     * 变更页码
     * @param {*} currentPage
     */
    currentChange(currentPage) {
      this.loading = true;
      this.query.page = currentPage;
      this.getOrders();
    },
    /**
     * 取消订单
     * @param {*} order_no
     */
    async cancleOrder(order_no) {
      await this.$axios.get(
        this.$apiBaseUrl + "/web/order/cancel?order_no=" + order_no
      );
      this.notifySucceed("订单取消成功!");
      this.$router.go(0);
    },
    /**
     * 订单详情
     * @param {*} order
     */
    getOrderInfo(order) {
      const routerUrl = this.$router.resolve( { path: '/orderInfo', query: { order_no : order.order_no}})
      window.open(routerUrl.href, "_blank");
    },
    /**
     * 批量付款
     */
    batchPay() {
      this.notifySucceed("开发中!");
    },
    /**
     * 批量取消
     */
    batchCancel() {
      this.notifySucceed("开发中!");
    },
    /**
     * 订单支付跳转
     * @param {*} order
     */
    async payOrder(order) {
      const payTotal = getTotalPrice(
        order.goods_total,
        order.express_fee,
        order.daifa_fee,
        order.check_fee,
        order.package_fee,
        order.brand_fee,
        order.wishcard_fee
      );
      if (parseFloat(payTotal) == parseFloat(order.gold)) {
        // 全金币支付
        let response = await this.$axios.get(
          this.$apiBaseUrl + "/web/gold/pay?order_no=" + order.order_no
        );
        if (response.data.code === 1) {
          this.notifySucceed("支付成功，即将跳转我的订单页面!");
        } else {
          this.notifyError(response.data.msg);
        }
      } else {
        window.location.href = this.$apiBaseUrl + '/web/pay/app?web=1&order_no=' + order.order_no;          
      }
    },
    /**
     * 打开退单弹窗
     * @param {*} order
     */
    openRefundDialog(order) {
      this.form.order_no = order.order_no;
      this.form.order_express_no = order.express_no;
      this.dialogReasonVisible = true;
    },
    /**
     * 申请退单
     */
    async refundOrder() {
      await this.$axios.post(this.$apiBaseUrl + "/web/refund/save", {
        uid: this.query.uid,
        order_no: this.form.order_no,
        reason: this.form.reason,
      });
      this.notifySucceed(
        this.form.order_express_no
          ? "您的退货退款申请已发送给代发商家，请耐心等待审核!"
          : "您的退款申请已发送给代发商家，请耐心等待审核!"
      );
      this.dialogReasonVisible = false;
    },
    /**
     * 打开退单号回填表单
     * @param {*} order_no
     */
    openRefundInfoDialog(order_no) {
      this.form.order_no = order_no;
      this.dialogFormVisible = true;
    },
    /**
     * 补充退单号
     * @param {*} order_no
     */
    async completeRefundExpressInfo() {
      await this.$axios.post(
        this.$apiBaseUrl +"/web/refund/express", this.form);
      this.dialogFormVisible = false;
      this.notifySucceed("商家已收到您的退单号，请耐心等待退款!");
    },
    /**
     * 查看物流
     * @param {*} company
     * @param {*} express_no
     */
    async getExpressInfo(company, express_no) {
      const { data } = await this.$axios.post(
        this.$apiBaseUrl +
          "/web/express/query", {
            company: company,
            express_no: express_no,
          }
      );
      this.shipData = data.data;
      this.dialogShipVisable = true;
    },
  },
};
</script>

<style scoped>
.ucenter {
  width: 1225px;
  font-size: 14px;
  margin: 0 auto;
}
.ucenter #title {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #ff414d;
}
/* 左侧菜单 */
.collapse-item__title {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
}
.collapse-item__ul {
  padding: 5px 0 0 0;
}
.collapse-item__ul li {
  color: #757578;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  padding-left: 1em;
  cursor: pointer;
}
#top-page {
  text-align: right;
  padding-right: 10px;
  position: relative;
  top: -5px;
}
/* 右侧订单列表 */
#theader {
  width: 100%;
  background: #eee;
  color: #3d3d40;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
}
.order-info {
  width: 100%;
  background: #fff1e8;
  border: 1px solid #fff1e8;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: 30px;
  height: 30px;
  padding: 0 10px;
}
.order-info {
  width: 100%;
  height: 30px;
  line-height: 30px;
  border: 1px solid #fff1e8;
}
.order-info-main {
  padding: 0 10px;
}
.order-info-user {
  text-align: right;
  padding: 0 10px;
}
.order-info-main span,
.order-info-user span {
  font-size: 12px;
  color: #3d3d40;
}
/* 订单商品 */
.order-goods {
  display: flex;
  flex-direction: row;
  padding: 5px 0;
}
.goods-image .el-image {
  width: 60px;
  height: 60px;
}
.order-goods .goods-info {
  font-size: 12px;
  text-decoration: none;
  margin-left: 15px;
}
.goods-info div {
  line-height: 20px;
  height: 20px;
}
.goods-info .goods-info-name {
  white-space: nowrap;
  overflow-x: hidden;
  width: 160px;
  text-overflow: ellipsis;
}
.goods-info .goods-info-price {
  color: #db3032;
}
.goods-info-no span,
.goods-info-price span {
  color: #333;
}
.goods-spec {
  width: 100%;
  font-size: 12px;
  text-align: left;
  line-height: 20px;
  min-height: 30px;
  padding-top: 20px;
}
.order-total-money {
  font-size: 12px;
  width: 100%;
  text-align: center;
  color: #db3032;
}
.order-total-money div {
  line-height: 15px;
  height: 15px;
}
.order-status {
  padding-top: 10px;
  font-size: 12px;
  width: 100%;
  text-align: center;
}
.order-status div {
  line-height: 20px;
  height: 20px;
}
.order-express {
  height: 34px;
  line-height: 34px;
  font-size: 12px;
}
.order-express .el-col {
  border: 1px solid #fff1e8;
}
.order-express .goods-num {
  color: #1890ff;
  text-align: right;
  padding-right: 20px;
}
.order-action {
  display: block;
  font-size: 12px;
  line-height: 20px;
  color: #1890ff;
}
.order-action:hover {
  color: #db3032;
  cursor: pointer;
}
/* 底部 */
#bottom {
  background: #f4f4f4;
  height: 48px;
  line-height: 48px;
  font-size: 12px;
  margin-top: 20px;
}
#bottom-count {
  display: flex;
  flex-direction: row;
}
#pagination {
  text-align: center;
  margin: 15px 0;
}
#refund-dialog {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #e9e9e9;
}
</style>
